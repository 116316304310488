<template>
  <div v-if="centro">
    <div>
      <!-- SERVICIOS DESTACADOS -->
      <div v-if="show_servicios_destacados">
        <v-row>
          <v-col>
            <v-alert
                type="success"
                icon="mdi-alert-decagram"
            >{{ $t('Selecciona tu pack') }} 👇
            </v-alert>
          </v-col>
        </v-row>

        <!-- FLOATING ACTION BUTTON -->
        <v-navigation-drawer
            stateless permanent floating fixed right
            color="transparent"
            :width="'100px'"
            class="pa-5"
        >
          <v-btn
              @click="go_to_servicios"
              fab dark color="primary" class="mb-2">
            <v-icon>mdi-arrow-down</v-icon>
          </v-btn>
        </v-navigation-drawer>
        <template>
          <v-card
              v-for="(current_servicio, indexServicio) in servicios_gratis" :key="indexServicio"
              class="mt-3"
              elevation="8"
          >

            <v-card-text @click="set_servicio(current_servicio)">
              <v-container fluid>
                <v-layout class="pointer" row>
                  <v-flex xs10>
                    <h3>{{ current_servicio.descripcion }}</h3>
                    <p style="white-space: pre-line;">{{ current_servicio.detalle }}</p>
                  </v-flex>

                  <v-flex xs2 class="text-right">
                    <div v-if="user && user.descuento_online > 0">
                      <div class="precio" style="text-decoration: line-through; color: darkred">
                        {{ current_servicio.precio_chico | money }}
                      </div>
                      <div class="precio" style="font-weight: bold">
                        {{ get_precion_con_descuento(current_servicio) | money }}
                      </div>
                    </div>
                    <div v-else>
                      <div class="precio">{{ current_servicio.precio_chico | money }}</div>
                    </div>

                    <div class="duracion">
                      <v-icon>mdi-timer</v-icon>
                      {{ current_servicio.duracion | time2Minutes }}min
                    </div>
                  </v-flex>
                </v-layout>
                <v-layout class="pointer" row>
                  <v-flex class="text-right font-weight-bold">
                    {{ $t('SELECCIONAR') }}
                  </v-flex>
                </v-layout>
              </v-container>
            </v-card-text>
          </v-card>
        </template>
      </div>
    </div>
  </div>
</template>


<script>
import show_toolbar_mixin from "@/mixins/show_toolbar_mixin";
import send_stats_mixin from "../mixins/send_stats_mixin";

export default {
  name: 'Centro',
  mixins: [show_toolbar_mixin, send_stats_mixin],

  data() {
    return {
      show_servicios_destacados: true,
      show_mensaje_selecciona_zonas: true,
      show_mensaje_no_se_ha_encontrado_pack: false,
      show_btn_go_to_servicios: false,
      servicios: [], // Servicios del centro
      servicios_gratis: [], // Servicios de la categoría 9547 (CLIENTES NUEVOS SESIÓN GRATIS)
      include_genitales_masculinos: false
    }
  },

  methods: {
    get_servicios_gratis() {
      this.servicios_gratis = []
      this.centro.servicios.forEach((current_servicio) => {
        this.servicios.push({...current_servicio})
        this.servicios_gratis.push({...current_servicio})
      })
    },

    init_reserva() {
      this.$store.commit('reserva/reset_reserva')
      this.$store.commit('reserva/set_centro', this.$store.state.centro.centro.id)
    },

    go_to_servicios() {
      window.scroll({
        top: document.querySelector('.v-card:last-child')?.offsetTop ?? 0,
        behavior: 'smooth'
      })
    },

    set_servicio (servicio) {
      const { utm_source, utm_medium, utm_campaign } = this.get_utm_params()

      this.$store.commit('reserva/set_servicio', servicio)
      this.$store.commit('reserva/set_include_genitales_masculinos', this.include_genitales_masculinos)

      if (utm_source && utm_medium && utm_campaign) {
        this.$router.push({ name: 'calendar', query: { utm_source, utm_medium, utm_campaign } })
      } else {
        this.$router.push({ name: 'calendar' })
      }
    },

    get_precion_con_descuento(servicio) {
      let precio = this.user.sexo === "m" ? servicio.precio_chico : servicio.precio_chico
      return (precio - (this.user.descuento_online * precio / 100)).toFixed(2)
    },

    get_utm_params () {
      const utm_source = this.$route.query.utm_source
      const utm_medium = this.$route.query.utm_medium
      const utm_campaign = this.$route.query.utm_campaign
      return { utm_source, utm_medium, utm_campaign }
    }
  },

  async mounted() {
    this.init_reserva()
    this.get_servicios_gratis()
    this.reserva.sexo = this.user ? this.$store.state.auth.user.sexo : null
  },

  computed: {
    centro() {
      return this.$store.state.centro.centro
    },
    reserva() {
      return this.$store.state.reserva
    },
    user() {
      return this.$store.state.auth.user
    }
  },

  filters: {
    time2Minutes(value) {
      let vTime = value.split(':');
      let hours = parseInt(vTime[0]);
      let minutes = parseInt(vTime[1]);
      return ((hours * 60) + minutes);
    }
  },
}
</script>


<style lang="scss">
.v-btn--is-elevated {
  margin-top: 60px;
}

.flex-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
